<template>
  <div class="fixed left-0 bottom-0 w-full h-full flex justify-center items-end z-[100] bg-secondary-500 bg-opacity-20 cursor-pointer notch-pt" :class="{'before-notch-pt': $device.isIos && isApp}">
    <div class="md:max-w-[50rem] h-full overflow-hidden md:h-90 md:rounded-[3rem] md:mb-10 flex flex-col bg-white w-full h-full pb-safe"> <!-- lg:h-auto -->
      <slot @close="$emit('close')" name="content" class="overflow-auto shadow-24dp" :search="filterValue"/>
      <div v-if="acceptAction || acceptAction" class="flex justify-end p-10 ">
        <TextButton @click.native="onAction(true)" v-if="acceptAction">{{ acceptAction }}</TextButton>
        <TextButton @click.native="onAction(false)" v-if="declineAction">{{ declineAction }}</TextButton>
      </div>
    </div>
  </div>
</template>

<script>
import TextButton from "@/components/buttons/TextButton"

export default {
  components: {TextButton},
  props: {
    acceptAction: String,
    declineAction: String,
    closeOnClickOutside: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: 'regular'
    },
    fullHeightMobile: {
      type: Boolean,
      default: true
    },
    forceSize: {
      type: [Boolean, String],
      default: false
    }
  },
  data: () => ({
    filterValue: null
  }),
  mounted() {
    document.body.classList.toggle('lock', true);
  },
  beforeDestroy() {
    document.body.classList.toggle('lock', false);
  },
  methods: {
    onClickOutside() {
      if (this.closeOnClickOutside) {
        this.$emit('close', true, true);
      }
    },
    onInput(e) {
      this.filterValue = e.currentTarget.value;
    },
    onAction(e) {
      this.$emit('action', e);
    }
  },
  computed: {
    isApp() {
      return process.env.mobileApp
    }
  }
}
</script>
