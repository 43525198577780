<template>
  <transition :name="transition" @after-enter="showContent = true">
    <Popin v-if="type === 'default' && componentName"
           class="bg-secondary-500 bg-opacity-20"
           :close-on-click-outside="closeOnClickOutside"
           @close="close" :force-size="forceHeight" :width="width">
      <template v-slot:content="{}">
        <component @close="close" :is="componentInstance" :data="data" @response="onResponse"></component>
      </template>
    </Popin>
    <BasePopin v-if="type === 'popinV2' && componentName"
               @close="close">
      <component @close="close"
                 :is="componentInstance"
                 :data="data"
                 @response="onResponse"
                 :showContent="showContent"
                 v-click-outside="$screen.mobile || componentName === 'paymentMethod' || componentName === 'filterSearch' || componentName === 'login' ? () => {} : close"/>
    </BasePopin>
  </transition>
</template>

<script>
import {mapGetters} from "vuex";
import Popin from "~/components/Popin"
import BasePopin from "@/components/Popin/v2/BasePopin"
import {LOGIN_STEPS, POPIN_COMPONENTS} from "assets/js/constants/constant";
export default {
  components: {
    BasePopin,
    Popin,
  },
  data() {
    return {
      transition: 'popin-fade-enter',
      showContent: false,
    }
  },
  mounted() {
    this.openLoginPopin()
  },

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      type: 'overlayForm/type',
      componentName: 'overlayForm/component',
      callback: 'overlayForm/callback',
      forceHeight: 'overlayForm/forceHeight',
      width: 'overlayForm/size',
      data: 'overlayForm/data',
      busId: 'overlayForm/busId',
      clickOutside: 'overlayForm/clickOutside',
    }),
    closeOnClickOutside () {
      return this.component !== 'offer' && this.component !== 'picturePopin'
    },
    componentInstance() {
      const component = POPIN_COMPONENTS[this.componentName]
      return () => import(`@/components/Popin/${component}.vue`)
    }
  },
  methods: {
    openLoginPopin() {
      if (this.$auth.loggedIn && (!this.$auth.user?.cgu || !this.$auth.user?.has_phone_number) && this.config.app.phonePopin?.show) {
        this.$store.commit('register/incomplete', {value: true})
        this.$store.dispatch('openForm', {
          width: 'regular',
          component: 'login',
          type: 'popinV2',
          data: {
            origin: this.$route.name,
            step: LOGIN_STEPS.register
          }
        })
      }
    },
    onResponse(response) {
      this.$root.$emit(`${this.busId}-response`, response);
      this.close();
    },
    async close(clickOutside, triggerCallback = false, successPopin = null, data = null) {
      this.transition = 'popin-fade-exit'
      this.showContent = false
      const callback = this.callback
      this.$root.$emit(`${this.busId}-close`);

      this.$nextTick(() => {
        if(this.clickOutside || (!this.clickOutside && !clickOutside)) {
          this.$store.commit('overlayForm/reset', null);
        }

        this.transition = 'popin-fade-enter'
        if (triggerCallback && callback) {
          if (data) {
            this.$nextTick(async function() {
              callback(data)
            }.bind(this))
          } else {
            this.$nextTick(async function() {
              callback()
            }.bind(this))
          }
        }

        if (successPopin) {
          this.$nextTick(async () => {
            await this.$store.dispatch('openForm', {
              width: 'regular',
              component: successPopin,
              type: 'popinV2',
              data
            })
          })
        }
      })
    }
  }
}
</script>
