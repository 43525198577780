<template>
  <div class="flex justify-center px-12 w-full flex-col md:flex-row"
       :class="{'border-t py-8': topSeparation, 'pb-8': !topSeparation}">
    <button v-if="leftButton"
            class="w-full md:mr-2 mb-4"
            :class="leftButtonClass"
            @click="$emit('leftClick')">
      {{ leftButton.label }}
    </button>
    <button v-if="rightButton"
            class="w-full md:ml-2 mb-4"
            :class="rightButtonClass"
            @click="$emit('rightClick')">
      {{ rightButton.label }}
    </button>
  </div>
</template>

<script>

export default {
  props: {
    leftButton: {
      type: Object,
      default: null
    },
    rightButton: {
      type: Object,
      default: null
    },
    topSeparation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    leftButtonClass() {
      return `btn-${this.leftButton.type}`
    },
    rightButtonClass() {
      return `btn-${this.rightButton.type}`
    }
  },
}
</script>
