<template>
  <span v-if="owner && edit" class="flex group h-full relative cursor-pointer border border-solid border-gray-400"
        @click.stop.prevent="triggerFileOpen">
    <Picture :key="picture"
             ref="picture"
             class="h-full w-full"
             v-if="picture && !loading"
             :sizes="large ? ['150:150'] : ['100:100']"
             :src="picture"/>
    <div v-else
         class="rounded-full h-full w-full bg-gray-400">
      <Loading />
    </div>

    <div v-if="!loading"
         class="absolute h-full w-full hidden md:group-hover:block">
      <div class="flex justify-center items-center w-full h-full rounded-full bg-white bg-opacity-80">
        <AddImage class="w-10 h-10"/>
      </div>
    </div>

    <FileExplorer hidden
                  v-if="!resetFileExplorer"
                  ref="fileExplorer"
                  :loader="true"
                  :required="false"
                  class="mb-10"
                  :min="1" :max="1"
                  v-model="uploadPicture"
                  :multiple="false"/>
    <span v-if="!picture"
          class="text-14 w-full block text-white rounded-full  flex items-center justify-center"
          :style="{'background-color' : bg}">
      {{ letter }}
    </span>
  </span>
  <span v-else class="flex group rounded-full overflow-hidden border border-solid border-gray-400">
    <Picture :key="src"
             ref="picture"
             v-if="picture"
             class="overflow-webkit block w-full h-full rounded-full overflow-hidden"
             :sizes="large ? ['150:150'] : ['100:100']"
             :src="picture"/>
    <span v-if="!picture"
          class="text-14 w-full block text-white rounded-full  flex items-center justify-center"
          :style="{'background-color' : bg}">
      {{ letter }}
    </span>
  </span>
</template>

<script>
import AddImage from "~/assets/svg/add_image.svg?inline";
import FileExplorer from "@/components/form/FileExplorer"
import Loading from "@/components/Loading.vue"
import Picture from "~/components/Picture.vue"

export default {
  components: {Picture, FileExplorer, AddImage, Loading},
  props: {
    src: String,
    edit : Boolean,
    owner: Boolean,
    large: Boolean
  },
  data() {
    return {
      uploadPicture: null,
      resetFileExplorer: false,
      loading: false
    }
  },
  watch: {
    uploadPicture(value) {
      if (value && value[0] && value[0].length) {
        if (value[0] !== 'loading') {
          this.resetFileExplorer = true
          this.$nextTick(() => {
            this.resetFileExplorer = false
          });
          this.uploadProfilPicture(value[0])
        } else {
          this.loading = true
        }
      }
    },
  },
  methods: {
    triggerFileOpen() {
      if (this.owner && this.edit) {
        this.$refs.fileExplorer.$refs.inputFiles.$refs.file.click()
      }
    },
    async uploadProfilPicture(picture) {
      this.loading = true

      if (picture !== 'error' && picture !== 'errorSize') {
        const response = await this.$api.$put(process.env.apiUrl + '/api/v1/account', {image: picture});
        if (response && !response.error) {
          await this.$auth.fetchUser();
        }
      }

      this.loading = false
    }
  },
  computed: {
    picture() {
      if (this.src) {
        return this.src;
      } else if (this.owner && this.$auth.user) {
        return this.$auth.user.image;
      } else {
        return null;
      }
    },
    letter() {
      return this.$auth.user && this.$auth.user.pseudo ? this.$auth.user.pseudo.substr(0, 1).toUpperCase() : 'S';
    },
    bg() {
      const gender = this.$auth.user ? this.$auth.user.gender : 'M';
      let color = '#35C886'
      /*switch (gender) {
        case 'M' :
          color = '#9AE4C3';
          break;
        case 'F' :
          color = '#595F8C';
          break;
        case 'N' :
          color = '#335360';
          break;
        default :
          color = '#35C886';
      }*/
      return color;
    },

  }
}
</script>
