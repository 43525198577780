<template>
  <div class="text-14 flex flex-col items-center">
    <button class="btn-underline p-12 justify-end self-end"
            @click="$emit('continue')">{{  $t('cookies.continue')  }}</button>
    <div class="w-60 flex-shrink-0 justify-center flex">
      <img width="1500" height="300" :src="logo" class="w-full h-auto text-transparent" alt="Everide logo"/>
    </div>
    <p class="p-12">
      {{ $t('cookies.description') }}
    </p>
    <CookieFooter :leftButton="{type: 'outline', label: $t('cookies.params')}"
                    @leftClick="$emit('manageCookies')"
                    :rightButton="{type: 'primary', label: $t('cookies.accept')}"
                    @rightClick="$emit('acceptCookies')"/>
  </div>
</template>

<script>

import CookieFooter from "@/components/Popin/cookie/CookieFooter"

export default {
  components: {CookieFooter},
  data: () => ({
    logo: process.env.staticAssets + '/site/logo-primary.svg',
  }),
}
</script>
