<template>
  <transition class="z-[100]"  :name="transition" @after-enter="transition = 'slide-to-right'" @after-leave="transition = 'slide-to-left'">
    <Popin v-if="list" @close="close" :force-size="forceSize || search || $device.isMobile" :class="animation">
      <template v-slot:content="{}">
        <DeepSelector @close="close"
                      :has-footer="hasFooter"
                      :hint="hint"
                      :item-template="itemTemplate"
                      :has-cancel="hasCancel"
                      :full-list="fullList"
                      :popin="true"
                      :max="max"
                      :min="min"
                      :infos="infos"
                      :tree="list"
                      :countable="countable"
                      :value="value"
                      :label="label"
                      :has-search="search"
                      :isMultiple="multiple"
                      :type="type"
                      @complete="onSelect"/>
      </template>
    </Popin>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import DeepSelector from "~/components/DeepSelector"
import Popin from "~/components/Popin"

export default {
  components: {DeepSelector, Popin},
  data() {
    return {
      transition: 'slide-to-left'
    }
  },
  computed: {
    ...mapGetters({
      forceSize: 'selectorPopin/forceSize',
      list: 'selectorPopin/list',
      fullList: 'selectorPopin/fullList',
      multiple: 'selectorPopin/multiple',
      property: 'selectorPopin/property',
      mutation: 'selectorPopin/mutation',
      label: 'selectorPopin/label',
      search: 'selectorPopin/search',
      type: 'selectorPopin/type',
      infos: 'selectorPopin/infos',
      min: 'selectorPopin/min',
      max: 'selectorPopin/max',
      hasCancel: 'selectorPopin/hasCancel',
      busId: 'selectorPopin/busId',
      value: 'selectorPopin/value',
      itemTemplate: 'selectorPopin/itemTemplate',
      hasFooter: 'selectorPopin/hasFooter',
      hint: 'selectorPopin/hint',
      countable: 'selectorPopin/countable'
    }),
    animation() {
      return (this.$route.name.indexOf('product-add') === 0 || this.$route.name.indexOf('all') === 0)  && this.$screen.xs ? 'slide' : 'up';
    },
  },
  methods: {
    close() {

      this.$nextTick(() => {
        this.$store.commit('selectorPopin/list', null);
        this.$store.commit('selectorPopin/reset');
      })
    },
    onSelect(value) {

      this.$store.commit('selectorPopin/list', null);

      if (this.mutation) {
        this.$store.commit(this.mutation, {value, type: this.type, property: this.property});
      }

      if (this.busId) {
        this.$root.$emit(`${this.busId}-response`, {value, type: this.type, property: this.property});
      }

      this.$store.commit('selectorPopin/reset');
    }
  }
}
</script>
