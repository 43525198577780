<template>
  <div class="fixed left-0 bottom-0 w-full h-full flex justify-center items-end z-[99] bg-secondary-500 bg-opacity-20 cursor-pointer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BasePopin"
}
</script>

<style scoped>

</style>
