<template>
  <transition name="Popin">
    <Popin v-if="visible">
      <template v-slot:content="{}">
        <div class="flex flex-col min-h-full p-10">
          <div class="text-18 font-semibold font-heading mb-5">{{ title }}</div>
          <p class="text-black-light text-14 pb-5">{{ desc }}</p>
          <div class="flex justify-end text-12 mt-10 gap-4">
            <Button @click.stop.native="onAnswer(true)" :outline="true">{{$t('product.edit.quit.yes')}}</Button>
            <Button @click.stop.native="onAnswer(false)">{{$t('product.edit.quit.no')}}</Button>
          </div>
        </div>
      </template>
    </Popin>
  </transition>
</template>
<script>
import Button from "@/components/buttons/Button"
import Popin from "@/components/Popin/index.vue"

export default {
  components : {
    Popin,
    Button
  },
  data() {
    return {
      visible: false,
      resolver: null,
      title : null,
      desc: null
    }
  },
  methods: {
    onAnswer(value) {
      this.resolver(value);
      this.visible = false;
    },
    show(title, desc) {
      this.title = title;
      this.desc = desc;
      this.visible = true;
      this.promise = new Promise(resolve => {
        this.resolver = resolve;
      });
      return this.promise;
    },
    hide() {
      this.visible = false;
    }
  }
}
</script>
