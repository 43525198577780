<template>
  <v-expansion-panels>
    <v-expansion-panel
        v-for="(item) in items"
        :key="item.id">
      <v-expansion-panel-header
          class="flex text-14 text-secondary flex-row-reverse bg-background-light p-4 border-solid border-surface-light border">
        <SwitchButton :value="isSelected(item.id)"
                      @change="onSelect(item.id)"
                      :no-padding="true"
                      small
                      v-if="!item.mandatory"/>
        <span v-else class="text-primary text-right">{{ $t('cookies.mandatory') }}</span>

        <div class="ml-4 text-left">
          <span>{{ $t(`cookies.${item.id}.name`) }}</span>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="text-14">
        <p class="pt-8">{{ $t(`cookies.${item.id}.description`) }}</p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import SwitchButton from "@/components/form/SwitchButton"

export default {
  name: 'Accordion',
  components: {SwitchButton},
  props: {
    items: {
      type: Array,
      default: []
    },
    selectedItems: {
      type: Array,
      default: []
    },
  },
  methods: {
    isSelected(itemId) {
      const index = this.selectedItems.indexOf(itemId)
      return index > -1
    },
    onSelect(itemId) {
      this.$emit('onSelect', itemId)
    }
  }
}
</script>
