<template>
  <div class="fixed top-0 left-0 right-0 bottom-0 bg-secondary-500 bg-opacity-20 z-[100] flex items-end justify-end p-8">
    <div class="bg-white rounded-[1rem] max-w-[50rem]">
      <CookieManagement v-if="showCookieManagement"
                        @close="showCookieManagement = false"
                        @acceptPartialCookies="acceptPartialCookies"
                        @acceptCookies="acceptAllCookies"/>
      <CookieWelcome v-else
                     @continue="acceptPartialCookies([])"
                     @acceptCookies="acceptAllCookies"
                     @manageCookies="showCookieManagement = true"/>
    </div>
  </div>
</template>

<script>

import CookieManagement from "@/components/Popin/cookie/CookieManagement"
import CookieWelcome from "@/components/Popin/cookie/CookieWelcome"

export default {
  components: {CookieWelcome, CookieManagement},
  props: {
    data: Object,
  },
  data: () => ({
    logo: process.env.staticAssets + '/site/logo-primary.svg',
    showCookieManagement: false
  }),
  methods: {
    acceptAllCookies() {
      this.$cookieManager.cookies.filter(cookie => !cookie.mandatory).forEach(cookie => {
        cookie.accepted()
      })

      this.$cookieManager.setAcceptedCookies([...this.$cookieManager.cookies].map(cookie => cookie.id))
      this.updateCookieState()
    },
    acceptPartialCookies(selectedCookies) {
      this.$cookieManager.cookies.forEach(function(cookie) {
        if (!cookie.mandatory) {
          if (selectedCookies.indexOf(cookie.id) > -1) {
            cookie.accepted(this)
          } else {
            cookie.declined(this)
          }
        }
      }.bind(this))

      this.$cookieManager.setAcceptedCookies(this.selectedCookies)
      this.updateCookieState()
    },
    updateCookieState() {
      this.$store.dispatch('cookies/initCookies', false)
    }
  }
}
</script>
