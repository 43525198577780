<template>
  <div class="flex justify-between items-center px-12 py-8 border-b border-surface-light">
    <div class="w-60 flex-shrink-0 mr-4 sm:mr-24">
      <img width="1500" height="300" :src="logo" class="w-full h-auto text-transparent" alt="Everide logo"/>
    </div>
    <button @click="$emit('close')"
            class="flex justify-center items-center text-black bg-white border border-gray-400 rounded-full shadow-btn w-18 h-18">
      <DeleteIcon class="w-8 h-8"/>
    </button>
  </div>
</template>
<script>
import DeleteIcon from "~/assets/svg/delete.svg?inline";

export default {
  name: 'CookieHeader',
  components: {DeleteIcon},
  data: () => ({
    logo: process.env.staticAssets + '/site/logo-primary.svg',
  })
}
</script>
