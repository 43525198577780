<template>
  <div>
      <CookieHeader @close="$emit('close')" />
      <div class="px-12 py-8">
        <div class="text-16 mb-8 font-bold">{{ $t('cookies.manage') }}</div>
        <Accordion :items="cookies" :selected-items="selectedCookies" @onSelect="onSelect"/>
      </div>
      <CookieFooter :leftButton="{type: 'underline', label: $t('cookies.confirm')}"
                    @leftClick="$emit('acceptPartialCookies', selectedCookies)"
                    :rightButton="{type: 'primary', label: $t('cookies.authorize')}"
                    @rightClick="$emit('acceptCookies')"
                    top-separation/>
  </div>
</template>

<script>

import CookieHeader from "@/components/Popin/cookie/CookieHeader"
import Accordion from "@/components/Popin/cookie/CookieAccordion"
import CookieFooter from "@/components/Popin/cookie/CookieFooter"

export default {
  components: {CookieFooter, Accordion, CookieHeader},
  data:() => ({
    selectedCookies: []
  }),
  created() {
    const acceptedCookies = this.$cookieManager.getAcceptedCookies()
    if (acceptedCookies && acceptedCookies.length > 0) {
      this.selectedCookies = acceptedCookies
    }
  },
  computed: {
    cookies() {
      return this.$cookieManager.cookies
    },
  },
  methods: {
    onSelect(cookieId) {
      if (this.isSelected(cookieId)) {
        this.selectedCookies.splice(this.selectedCookies.indexOf(cookieId), 1)
      } else {
        this.selectedCookies.push(cookieId)
      }
    },
    isSelected(cookieId) {
      const index = this.selectedCookies.indexOf(cookieId)
      return index > -1
    },
  }
}
</script>
